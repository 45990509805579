import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PartnersEn = ({location}) => (
  <Layout lang="en" location={location}>
    <SEO title="Partners" description="Tourist entertainers in the Jura: campsites, guest houses, gites, hotels, etc." lang="en" />
    <h3>Partners</h3>
    <p>
    As far as possible, tourism is seen in a vision of
      overall and global, at least that's how I see it. It is
      important that professional and trusting relationships are formed between
      tourism players in the same territory.
    </p>
    <p>
    This page is dedicated to people and structures sharing this vision.
    </p>
    <p>
    Thank you to those who support me in my adventure.
      professional and who will support me.
    </p>
    <p>Enjoy your stay in our beautiful region!</p>
    <p>Ludovic Guyot</p>
    <p>Lacuzon loisirs</p>
    <h4 className="mt-5" style={{color: "#000"}}>Accommodation :</h4>
    <ul className="li-rando">
      <li>
        {" "}
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Campings</strong>
        </p>
        <p>
          camping *** la Saline-les bords de Loue, Arc-et-Senans (25) :{" "}
          <a href="https://www.camping-des-bords-de-loue.fr/">
            www.camping-des-bords-de-loue.fr
          </a>
        </p>
        <p>
          camping les promenades, Quingey (25) :{" "}
          <a href="https://www.quingeycamping.fr/">www.quingeycamping.fr</a>
        </p>
        <p>
          camping la petite montagne -Predulac, Moirans-en Montagne (39) :{" "}
          <a href="https://www.campinglapetitemontagne.com/">
            www.campinglapetitemontagne.com
          </a>
        </p>
      </li>
      <li>
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Bed and Breakfast:</strong>
        </p>
        <p>
          Au champ du bois, Sampans (39) :{" "}
          <a href="https://www.champsdubois.fr/">www.champsdubois.fr</a>
        </p>
        <p>
          Domaine de Bersaillin (39):{" "}
          <a href="https://www.domainedebersaillin.fr/">
            www.domainedebersaillin.fr
          </a>
        </p>
      </li>

      <li>
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Lodgings</strong>
        </p>

        <p>
          les Bouc'Tins, Molain (39) :{" "}
          <a href="http://lesbouctins.unblog.fr/page/2/">
            lesbouctins.unblog.fr
          </a>
        </p>

        <p>
          les 4 saisons, Marigny (39):
          <a href="https://www.lac-chalain.com/gite-les-quatre-saisons/">
            www.lac-chalain.com/gite-les-quatre-saisons
          </a>
        </p>
      </li>
      <li>
        <p>
          <strong style={{color: "#000", fontSize: 18}}>Hotels</strong>
        </p>

        <p>
          Hôtel-Restaurant le Castel d'amandre, Planches-près d'Arbois
          : <a href="https://www.casteldamandre.com/fr/">www.casteldamandre.com</a>
        </p>
      </li>
    </ul>
  </Layout>
)

export default PartnersEn
